import React, { useEffect, useState, useCallback } from 'react';
import { Table, Form, Alert } from 'react-bootstrap';

import { useAccount, useContractRead, useContractWrite, useWaitForTransaction } from 'wagmi';
import { Network, Alchemy } from 'alchemy-sdk';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar, faHandHoldingDroplet } from '@fortawesome/free-solid-svg-icons';

import abi from '../contracts/HiveABI.json';

import { plural, weiConverter } from './Utils.js'

const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: Network.MATIC_MAINNET,
};

const alchemy = new Alchemy(settings);
const REACT_APP_SC_ADDRESS = process.env.REACT_APP_SC_ADDRESS;
const REACT_APP_HIVE_SC_ADDRESS = process.env.REACT_APP_HIVE_SC_ADDRESS;

const NFTsTable = ({ userNFTs, userNFTsBalances, selectedNFTs, handleCheckboxChange, handleClaimUSDT, handleClaimMATIC }) => {

    let totalUSDT = 0;
    let totalMATIC = 0;
    let totalSelectedUSDT = 0;
    let totalSelectedMATIC = 0;

    if (userNFTsBalances[0]) {
        totalUSDT = userNFTsBalances[0].reduce((acc, balance) => acc + Number(balance || 0), 0);
        totalSelectedUSDT = userNFTs.reduce((acc, nft, index) => {
            return selectedNFTs.usdt.has(nft) ? acc + Number(userNFTsBalances[0][index] || 0) : acc;
        }, 0);
    }

    if (userNFTsBalances[1]) {
        totalMATIC = userNFTsBalances[1].reduce((acc, balance) => acc + Number(balance || 0), 0);
        totalSelectedMATIC = userNFTs.reduce((acc, nft, index) => {
            return selectedNFTs.matic.has(nft) ? acc + Number(userNFTsBalances[1][index] || 0) : acc;
        }, 0);
    }

    return (<>

        <Alert variant='light' className='text-center'>Vous disposez de <b>{userNFTs.length}</b> NFT{plural(userNFTs.length)} BitBee</Alert>

        <div className="table-responsive">
            <Table hover>
                <thead className='text-center'>
                    <tr>
                        <th>ID BitBee</th>
                        <th colSpan={2}>Airdrops disponibles</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {userNFTs.map((nft, index) => (
                        <tr key={nft}>
                            <td>#{nft}</td>
                            <td>{userNFTsBalances[0] && (
                                <Form.Check
                                    inline
                                    type="switch"
                                    value={nft}
                                    id={`inline-switch-usdt-${index}`}
                                    onChange={() => handleCheckboxChange(nft, 'usdt')}
                                    checked={selectedNFTs.usdt.has(nft)}
                                    disabled={userNFTsBalances[0] && Number(userNFTsBalances[0][index]) <= 0}
                                    label={`${weiConverter(Number(userNFTsBalances[0][index]), 6)} USDT`}
                                />
                            )}
                            </td>
                            <td>{userNFTsBalances[0] && (
                                <Form.Check
                                    inline
                                    type="switch"
                                    value={nft}
                                    id={`inline-switch-matic-${index}`}
                                    onChange={() => handleCheckboxChange(nft, 'matic')}
                                    checked={selectedNFTs.matic.has(nft)}
                                    disabled={userNFTsBalances[1] && Number(userNFTsBalances[1][index]) <= 0}
                                    label={`${weiConverter(Number(userNFTsBalances[1][index]), 18)} MATIC`}
                                />
                            )}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td><strong>Total récupérable</strong></td>
                        <td><strong>{weiConverter(totalUSDT, 6)} USDT</strong></td>
                        <td><strong>{weiConverter(totalMATIC, 18)} MATIC</strong></td>
                    </tr>
                </tbody>
            </Table>

            <div className="text-center">
                {Array.from(selectedNFTs.usdt).length > 0 ? (<>
                    <button className="single-item-btn btn-light btn-sm m-1" onClick={handleClaimUSDT} disabled={Array.from(selectedNFTs.usdt).length == 0}>
                        <FontAwesomeIcon icon={faHandHoldingDollar} className="px-2" /> Claim {weiConverter(totalSelectedUSDT, 6)} USDT
                    </button>
                </>) : null}
                {Array.from(selectedNFTs.matic).length > 0 ? (<>
                    <button className="single-item-btn btn-light btn-sm m-1" onClick={handleClaimMATIC} disabled={Array.from(selectedNFTs.matic).length == 0}>
                        <FontAwesomeIcon icon={faHandHoldingDroplet} className="px-2" /> Claim {weiConverter(totalSelectedMATIC, 18)} MATIC
                    </button>
                </>) : null}
            </div>

        </div>
    </>);
};

export function Claim() {

    const { address, isConnected } = useAccount();
    const [userNFTs, setUserNFTs] = useState([]);
    const [userNFTsBalances, setUserNFTsBalances] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedNFTs, setSelectedNFTs] = useState({ usdt: new Set(), matic: new Set() });
    const [selectedNFTsForClaim, setSelectedNFTsForClaim] = useState({ tokens: [], currency: '' });

    // Update all internal balances on NFTs Balances update
    useEffect(() => {
        if (userNFTsBalances[0]) {
            const usdtSet = new Set();
            const maticSet = new Set();

            userNFTs.forEach((nftId, index) => {
                if (Number(userNFTsBalances[0][index] || 0) > 0) {
                    usdtSet.add(nftId);
                }
                if (Number(userNFTsBalances[1][index] || 0) > 0) {
                    maticSet.add(nftId);
                }
            });

            setSelectedNFTs({ usdt: usdtSet, matic: maticSet });
        }
    }, [userNFTsBalances]);
    //}, [userNFTs, userNFTsBalances]);

    // SC write function
    const { data: batchWithdrawData, write: batchWithdrawWrite, error: batchWithdrawError } = useContractWrite({
        address: REACT_APP_HIVE_SC_ADDRESS,
        abi: abi,
        functionName: 'batchWithdraw',
        args: [selectedNFTsForClaim.tokens, selectedNFTsForClaim.currency === 'usdt']
    });

    const { isLoading: batchWithdrawIsLoading, isSuccess: batchWithdrawIsSuccess, isError: batchWithdrawIsError, } = useWaitForTransaction({
        hash: batchWithdrawData?.hash
    });

    // Handle checkbox change
    const handleCheckboxChange = (nft, currency) => {
        setSelectedNFTs(prevSelected => {
            const newSelected = new Set(prevSelected[currency]);
            if (newSelected.has(nft)) {
                newSelected.delete(nft);
            } else {
                newSelected.add(nft);
            }
            return { ...prevSelected, [currency]: newSelected };
        });
    };

    // Handle claim USDT
    const handleClaimUSDT = () => {
        setSelectedNFTsForClaim({ tokens: Array.from(selectedNFTs.usdt), currency: 'usdt' });
    };

    // Handle claim MATIC
    const handleClaimMATIC = () => {
        setSelectedNFTsForClaim({ tokens: Array.from(selectedNFTs.matic), currency: 'matic' });
    };

    // Handle SC write
    useEffect(() => {
        if (selectedNFTsForClaim.tokens.length > 0) {
            batchWithdrawWrite();
        }
    }, [selectedNFTsForClaim]);

    // Get Balances for all the tokens of the user
    useContractRead({
        address: REACT_APP_HIVE_SC_ADDRESS,
        abi: abi,
        functionName: 'getBalances',
        args: [userNFTs],
        enabled: userNFTs.length >= 1,
        watch: true,
        onSuccess(data) {
            setUserNFTsBalances(data)
        }
    })

    // Get all NFTs owned by the user
    const getUserNFTs = useCallback(async () => {
        if (isConnected) {
            try {
                let allTokenIds = [];
                let pageKey = null;
                setIsLoading(true);
                do {
                    const nfts = await alchemy.nft.getNftsForOwner(address, {
                        contractAddresses: [REACT_APP_SC_ADDRESS],
                        pageKey: pageKey
                    });

                    const tokenIds = Object.keys(nfts.ownedNfts).map(key => Number(nfts.ownedNfts[key].tokenId));
                    allTokenIds = [...allTokenIds, ...tokenIds];

                    pageKey = nfts.pageKey;

                } while (pageKey);

                setUserNFTs(allTokenIds);
                setIsLoading(false);

            } catch (error) {
                console.error('Error during getting user NFTs', error);
            }
        }
    }, [isConnected, address]);

    useEffect(() => {
        getUserNFTs();
    }, [getUserNFTs]);

    return (<>
        <p className="site-title-tagline text-center">Suivez et réclamez vos gains</p>
        {isConnected ? (<>
            {isLoading == true ? (<p className="text-center">Chargements de vos NFTs en cours</p>) : (<>

                {userNFTs.length >= 1 ? (<>
                    <NFTsTable userNFTs={userNFTs} userNFTsBalances={userNFTsBalances} selectedNFTs={selectedNFTs} handleCheckboxChange={handleCheckboxChange} handleClaimUSDT={handleClaimUSDT} handleClaimMATIC={handleClaimMATIC} />

                    {batchWithdrawError && batchWithdrawError.cause.reason && batchWithdrawError.cause.reason && (<Alert variant='danger' className='text-center my-2'>Claim impossible : {batchWithdrawError.cause.reason}</Alert>)}
                    {batchWithdrawIsLoading && (<Alert variant='light' className='text-center my-2'>Claim en cours...</Alert>)}
                    {batchWithdrawIsSuccess && (<Alert variant='success' className='text-center my-2'>Claim réalisé avec succès...</Alert>)}
                    {batchWithdrawIsError && (<Alert variant='danger' className='text-center my-2'>Erreur lors du claim...</Alert>)}

                </>) : (
                    <Alert variant='danger' className='text-center'>Désolé, aucun <b>BitBee</b> (NFT Honey Airdrop) détecté sur ce wallet...</Alert>)}

            </>)}
        </>) : (<>
            <Alert variant='danger' className='text-center'>Connexion requise pour lister et claim vos gains</Alert>
        </>)}
    </>
    );
}