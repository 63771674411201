import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

import slider from "../assets/img/project_character.png";
import shape from "../assets/img/shape/04.svg";

import { Link } from "react-router-dom";

const HeroSection = () => (
  <div className="hero-section">
    <div className="hero-single">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="hero-content">
              <h6 className="hero-sub-title">Honey Airdrop</h6>
              <h1 className="hero-title">LE HUB DE FARMING <span>AIRDROPS</span></h1>
              <p className="hero-text">757 abeilles qui mutualisent leurs fonds pour pouvoir butiner ensemble les airdrops des nouveaux protocoles blockchains</p>
              <div className="hero-btn">
                <Link to="/Mint" className={"theme-btn theme-btn2 d-none"}>Mint <FontAwesomeIcon icon={faArrowRight} fade className="ms-2" /></Link>
                <Link to="/Dashboard" className={"theme-btn theme-btn2"}>Dashboard <FontAwesomeIcon icon={faScrewdriverWrench} flip className="ms-2" /></Link>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="hero-img">
              <img src={slider} alt="Honey Airdrop" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="hero-shape">
      <img src={shape} alt="" />
    </div>
  </div>
);

export default HeroSection;