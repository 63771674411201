import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const Breadcrumb = ({ pageName }) => {
  return (<>
    <div className="site-breadcrumb">
    <Container>
      <h2 className="breadcrumb-title">{pageName}</h2>

    </Container>
  </div>
  </>);
}

export default Breadcrumb;