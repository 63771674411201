import React, { useState, useEffect } from 'react';
import { useDebounce } from 'usehooks-ts';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faCoins } from '@fortawesome/free-solid-svg-icons';
import { useContractRead, useContractEvent, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { useAccount } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { formatEther, formatUnits, parseEther } from 'viem';

import Breadcrumb from './components/Breadcrumb';
import nft from "./assets/img/project_character.png";
import abi from './contracts/ABI.json';

export function Mint() {

    const [amount, setAmount] = useState(1);
    const debouncedAmount = useDebounce(amount, 250);

    const [mintPhase, setMintPhase] = useState();
    const [currentPhase, setCurrentPhase] = useState(0);
    const [mintedBees, setMintedBees] = useState(0);
    const [publicMintPrice, setPublicMintPrice] = useState(0);

    const isMintPhase = currentPhase >= 1 && currentPhase <= 4;
    const isPrivateMintPhase = currentPhase >= 1 && currentPhase <= 3;

    const [allowedMints, setAllowedMints] = useState(0);
    const [allowedMintsMinusUsed, setAllowedMintsMinusUsed] = useState(0);
    const [usedMints, setUsedMints] = useState(0);
    const [mtProof, setMtProof] = useState([]);

    const { address, isConnected } = useAccount();
    const allowlistServer = "https://allowlist.rpgmax.fr/hdrop-p";

    // Check l'allowlist server en fonction de la phase et de l'adresse de l'utilisateur

    useEffect(() => {
        if (currentPhase >= 1 && currentPhase <= 3) {
            async function fetchDetails(address) {
            if (!address) return;
        
            try {
                const response = await fetch(`${allowlistServer}${currentPhase}/${address}`);
                const details = await response.json();
                
                setAllowedMints(details[0]);
                setMtProof(details[1] || []);
            } catch (err) { console.log(err); }
            }
        
            fetchDetails(address);
        } else if (currentPhase == 4 ) { setAllowedMints(10); }
      }, [address, currentPhase]);


      // Set max mint - used mint
    useEffect(() => {
        setAllowedMintsMinusUsed(allowedMints - usedMints);
    }, [allowedMints, usedMints]);

    // Récupération de la phase en cours
    useContractRead({ address: process.env.REACT_APP_SC_ADDRESS, abi: abi, functionName: 'currentPhase', onSuccess(data) { setCurrentPhase(parseInt(data)); } })

    // Récupération des NFTs déjà mintés
    useContractRead({ address: process.env.REACT_APP_SC_ADDRESS, abi: abi, functionName: 'mintedBees', watch: true, onSuccess(data) { setMintedBees(parseInt(data)); } })

    // Récupération des infos de la phase en cours
    useContractRead({
        address: process.env.REACT_APP_SC_ADDRESS,
        abi: abi,
        functionName: 'getMintPhase',
        args: [currentPhase],
        enabled: isMintPhase,
        onSuccess(data) {
            setMintPhase(data);
            const etherPrice = formatEther(data.price);
            const updatedData = { ...data, price: etherPrice };
            setMintPhase(updatedData);
        }
    })
    
    // Récupération du tracker de mint pour la phase en cours et le wallet actuellement co
    useContractRead({
        address: process.env.REACT_APP_SC_ADDRESS,
        abi: abi,
        functionName: 'getMintTracker',
        args: [currentPhase, address],
        enabled: isPrivateMintPhase,
        watch: true,
        onSuccess(data) { setUsedMints(formatUnits(data, 0)); }
    })

    // Récupération du prix de la phase publique
    useContractRead({ address: process.env.REACT_APP_SC_ADDRESS, abi: abi, functionName: 'getMintPhase', args: [4], onSuccess(data) { setPublicMintPrice(formatEther(data.price)); } })

    // En cas de changement de phase dans le SC on répercute l'info dans la DApp et on refait une simulation de mint
    useContractEvent({
        address: process.env.REACT_APP_SC_ADDRESS,
        abi: abi,
        eventName: 'PhaseUpdate',
        listener(log) {
            var newCurrentPhase = parseInt(log[0].args.phaseID);
            setCurrentPhase(newCurrentPhase);
            if (newCurrentPhase == 4) { setUsedMints(0); } // Sécurité pour écraser l'ancien compteur de mints lors du passage en phase publique
            mintRefetch();    
        },
      })

    // Préparation du mint avec quantité, max mint possible (pour les phases réservées) et MT proof
    const {
        config: mintConfig,
        error: mintPrepareError,
        isError: mintIsPrepareError,
        isSuccess: mintIsPrepareSuccess,
        refetch : mintRefetch,
    } = usePrepareContractWrite({
        address: process.env.REACT_APP_SC_ADDRESS,
        abi: abi,
        functionName: 'mint',
        args: [debouncedAmount, allowedMints, mtProof],
        value: mintPhase?.price ? parseEther((mintPhase.price * debouncedAmount).toString()) : 0,
        enabled: Boolean(address && isMintPhase && mintPhase)
    });

    const { data: mintData, write: mintWrite } = useContractWrite(mintConfig);

    const { isLoading : mintIsLoading, isSuccess: mintIsSuccess } = useWaitForTransaction({
        hash: mintData?.hash,
        onSuccess() { setAmount(1); } // Post mint on repasse la quantité à 1 (par défaut)
    });

    useEffect(() => { if(mintIsSuccess) mintRefetch() }, [mintIsSuccess]); // Post mint on refait une simulation de mint

    // Incrémentation/Réduction de la quantité à mint
    const increaseAmount = () => { if (amount < allowedMintsMinusUsed) { setAmount(prevAmount => prevAmount + 1); } } // Incrémentation mais avec limite

    const decreaseAmount = () => { if (amount > 1) { setAmount(prevAmount => prevAmount - 1); } }// Réduction sans pouvoir aller à zéro

    // Permet d'avoir maxi 2 chiffres après la virgule si nécessaire, sinon uniquement l'entier
    function formatNumber(num) {
        const formatted = num.toFixed(2);
        return formatted.endsWith('.00') ? formatted.slice(0, -3) : formatted;
    }

    // Description des phases
    const phasesDescription = [
        { id: 0, name: 'Les abeilles se rassemblent (en attente de lancement) !' },
        { id: 1, name: 'Free mints' },
        { id: 2, name: 'Mints réservés aux holdeurs Cryptocademia' },
        { id: 3, name: 'Mints réservés aux holdeurs de communautés amies' },
        { id: 4, name: 'Mint public' },
        { id: 5, name: 'Sold Out - Les abeilles sont rentrées à la ruche !' }
    ];

    // Messages d'erreur plus lisibles
    const friendlyErrorMessage = (errorMsg) => {
        const errorMappings = {
        "Hey, you aren't on the current phase allowlist :/": "Vous ne disposez pas de mint pour cette phase.",
        "Hey, no more mint allowed for the current phase !": "Vous ne disposez plus de mint pour cette phase",
        "Oh no, supply overrun :/" : "Désolé, la quantité dépasse le supply restant !",
        "Sold out !" : "Sold out !",
        "Invalid mint price !" : "Erreur de calcul du prix, veuillez retenter"
    };

    const errorMessage = errorMappings[errorMsg];

    if (typeof errorMessage === 'function') { return errorMessage(); }
    return errorMessage || 'Vérifiez que vous disposez de suffisament de MATIC sur ce wallet'; // Message d'erreur par défaut
    };

  return (
    <main className="main">
      <Breadcrumb pageName="Mint" />

      {!isConnected ? (<><div className="site-heading text-center mt-4">
                <h2 className="site-title">Une abeille se doit d'être <span>connectée</span> à sa ruche...</h2>
                <div className="connect-zone my-4"><ConnectButton /></div>
                <div className="heading-divider"></div>
            </div></>) : (

      <div className="shop-item-single">

      <Container className="my-4">
        <Row>
          <Col lg={6} className="mx-auto">

            <div className="site-heading text-center">
                <h2 className="site-title">Rejoignez la <span>ruche</span> !</h2>
                <div className="connect-zone my-4"><ConnectButton /></div>
                <div className="heading-divider"></div>
            </div>

          </Col>
        </Row>

        <Row>
            <Col lg={5}>
                <div className="item-gallery mb-5">
                    <div className="flexslider-thumbnails">
                        <div className="flex-viewport" style={{ overflow: 'hidden', position: 'relative' }}>
                            <Image src={nft} alt="NFT du projet Honey Airdrop" />
                        </div>
                    </div>
                </div>
            </Col>
            <Col lg={7}>
                <div className="single-item-info">
                    <h4 className="single-item-title">NFT HDROP</h4>
                    <div className="single-item-price">
                        {isMintPhase ? (<>

                        {isPrivateMintPhase && mintPhase && (<h4><del>{formatNumber(Number(publicMintPrice * amount))} MATIC</del><span>{formatNumber(Number(mintPhase.price * amount))} MATIC</span></h4>)}
                        {currentPhase == 4 && mintPhase && (<h4><span>{formatNumber(Number(publicMintPrice * amount))} MATIC</span></h4>)}

                        </>) : (
                            <h4>Prix public : <span>{formatNumber(Number(publicMintPrice * amount))} MATIC</span></h4>)
}
                    </div>
                    <p className="my-4">Ce NFT de type ERC-721 vous permet d'obtenir l'équivalent d'une part des airdrops récoltés par le projet. Il permet également l'intégration de la partie holder du projet Honey Airdrop donnant accès à du contenu exclusif et éducatif sur les techniques de farm et celles en cours d'utilisations.</p>
                    <div className="single-item-content">
                        <h5>Supply pré-burn : <span>{mintedBees} / 888</span></h5>
                    </div>
                    <div className="single-item-content">
                        <h5>Phase actuelle : <span>{phasesDescription.find(phase => phase.id === currentPhase)?.name || 'Phase inconnue'}</span></h5>
                    </div>
                    {isMintPhase && (<>
                    <div className="single-item-content">
                        <h5>Statut :
                            {isPrivateMintPhase && (<>                        
                                {allowedMints >= 1 ? (<span className="text-success">Éligible</span>) : (<span className="text-danger">Inéligible</span>)}
                            </>)}
                            {currentPhase == 4 && (<><span className="text-success">Éligible</span></>)}
                        </h5>
                    </div></>)}

                    {isMintPhase && mintedBees < 888 && (<>
                    <div className="single-item-action">
                        <h5 className="title">Quantité :</h5>
                        <div className="cart-qty">
                            <button className="minus-btn mx-1" onClick={decreaseAmount} disabled={amount === 1}>
                                <FontAwesomeIcon icon={faMinus} />
                            </button>
                            <input className="quantity" type="text" value={amount} readOnly />
                            <button variant="light" className="plus-btn mx-1" onClick={increaseAmount} disabled={amount === allowedMintsMinusUsed}>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                        <div className="item-single-btn-area">
                            <button className={`single-item-btn ${mintIsPrepareError ? 'btn-danger' : 'btn-light'}`} disabled={mintIsLoading || !mintIsPrepareSuccess} onClick={mintWrite}>
                                <FontAwesomeIcon icon={faCoins} className="px-2" /> Mint
                            </button>
                        </div>
                    </div></>)}

                    <hr />
                    <div className="single-item-content">

                        {mintIsPrepareError && isMintPhase && (<>
                        <div className="single-item-content">
                            <h5>Information : <span className="text-danger">{friendlyErrorMessage(mintPrepareError.cause.reason)}</span></h5>
                        </div></>)}

                        {mintIsSuccess && !mintIsLoading && (<>
                            <div className="single-item-content">
                                <h5>Statut du mint : <span className="text-success">Réussi</span></h5>
                            </div>
                            <div className="single-item-content">
                                <h5>Explorer : <span><a href={`https://${process.env.REACT_APP_SC_CHAIN_EXPLORER}/tx/${mintData.hash}`} target="_blank">Voir la transaction </a></span></h5>
                            </div>
                        </>)}
                    </div>
                </div>
            </Col>
        </Row>

      </Container>

      </div>)}

    </main>
  );
}