import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useAccount } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import Breadcrumb from './components/Breadcrumb';

export function Check() {


    const [phase2Mints, setPhase2Mints] = useState(null);
    const [phase3Mints, setPhase3Mints] = useState(null);

    const { address, isConnected } = useAccount();

    const allowlistServer = "https://allowlist.rpgmax.fr/hdrop-p";

    const plural = (count) => { if (count >= 2) return 's'; }

    const handleSearch = async (address) => {

        if (!address) return;
        try {
            // Phase 2
            const p2Response = await fetch(`${allowlistServer}2/${address}`);
            const p2Details = await p2Response.json();            
            setPhase2Mints(p2Details[0]);
            // Phase 3
            const p3Response = await fetch(`${allowlistServer}3/${address}`);
            const p3Details = await p3Response.json();            
            setPhase3Mints(p3Details[0]);
      } catch (err) { console.log(err); }
      };

    useEffect(() => {handleSearch(address) }, [address])

  return (
    <main className="main">
      <Breadcrumb pageName="Vérification WL" />

      {!isConnected ? (<><div className="site-heading text-center mt-4">
                <h2 className="site-title">Une abeille se doit d'être <span>connectée</span> à sa ruche...</h2>
                <div className="connect-zone my-4"><ConnectButton /></div>
                <div className="heading-divider"></div>
            </div></>) : (

      <div className="shop-item-single">

      <Container className="my-3">
        <Row>
          <Col lg={6} className="mx-auto">

            <div className="site-heading text-center">
                <h2 className="site-title">Confirmez votre place dans <span>l'essaim</span> !</h2>
                <div className="connect-zone my-4"><ConnectButton /></div>
                <div className="heading-divider"></div>
            </div>

          </Col>
        </Row>

        {phase2Mints === null && phase3Mints === null ? (<p className="text-center">Patience...</p>) : null }
        {phase2Mints == 0 && phase3Mints == 0 ? (<p className="text-center">Désolé, ce wallet n'est whitelist pour aucune des phases de mint 😢<br />Essayez avec un autre ou rendez-vous en phase publique...</p>) : null }

        {phase2Mints >= 1 || phase3Mints >= 1 ? (<p className="text-center">Félicitations, ce wallet est whitelist pour au moins une phase de mint !🔥</p>) : null }
        {phase2Mints >= 1 ? (<p className="my-0 text-center">Phase mint <b>Cryptocademia</b> : {phase2Mints} mint{plural(phase2Mints)} autorisé{plural(phase2Mints)}<br /></p>) : null }
        {phase3Mints >= 1 ? (<p className="my-0 text-center">Phase mint <b>communautés amies</b> : {phase3Mints} mint{plural(phase3Mints)} autorisé{plural(phase3Mints)}</p>) : null }
        
      </Container>

      </div>)}

    </main>
  );
}