import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faHeart, faStore } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faDiscord, faMedium } from '@fortawesome/free-brands-svg-icons';

import { Link } from "react-router-dom";

import footer from "../assets/img/footer.jpg";

import logo from "../assets/img/logo-footer.png";

export function Footer() {
  const contractAddress = process.env.REACT_APP_SC_ADDRESS;
  const hiveContractAddress = process.env.REACT_APP_HIVE_SC_ADDRESS;
  const contractChainExplorer = process.env.REACT_APP_SC_CHAIN_EXPLORER;
  const version = process.env.REACT_APP_VERSION;

  return (
    <footer className="footer-area">
      <div className="footer-shape">
        <Image src={footer} className="footer-shape-1" />
      </div>
      <div className="footer-widget">
        <Container>
          <Row className="footer-widget-wrapper pt-100 pb-70">
            <Col md={{ span: 4, offset: 0 }} className="text-center">
              <img src={logo} alt="Honey Airdrop" />
              <p className="p-4">757 abeilles qui mutualisent leurs fonds pour pouvoir butiner ensemble les airdrops des nouveaux protocoles blockchains.</p>
            </Col>
            <Col xs={6} md={{ span: 3, offset: 2 }}>
              <div className="footer-widget-box list">
                <h4 className="footer-widget-title">Honey Airdrop</h4>
                <ul className="footer-list">
                  <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> Accueil</Link></li>
                  <li className='d-none'><Link to="/Check"><FontAwesomeIcon icon={faCaretRight} /> Vérification WL</Link></li>
                  <li className='d-none'><Link to="/Mint"><FontAwesomeIcon icon={faCaretRight} /> Mint</Link></li>
                  <li><Link to="/Dashboard"><FontAwesomeIcon icon={faCaretRight} /> Dashboard</Link></li>
                  <li><Link to="/Team"><FontAwesomeIcon icon={faCaretRight} /> L'équipe</Link></li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3}>
              <div className="footer-widget-box list">
                <h4 className="footer-widget-title">Liens utiles</h4>
                <ul className="footer-list">
                  <li><a href="https://marketplace.honeyairdrop.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faStore} /> Marketplace</a></li>
                  <li><a href="https://medium.com/@honeyairdrop" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faMedium} /> Medium</a></li>
                  <li><a href="https://twitter.com/HoneyAirdrop" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /> Twitter</a></li>
                  <li><a href="https://discord.com/invite/BqRh2YWPWu" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faDiscord} /> Discord</a></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="copyright">
        <Container>
          <Row>
            <Col md={12} className="align-self-center">
              <p className="copyright-text">
                <span className="sc">Smart Contracts :<br />NFTs : <a href={`https://${contractChainExplorer}/address/${contractAddress}`} target="_blank" rel="noopener noreferrer">{contractAddress}</a><br />Hive : <a href={`https://${contractChainExplorer}/address/${hiveContractAddress}`} target="_blank" rel="noopener noreferrer">{hiveContractAddress}</a></span><br />
                <span className='made-with-love'>DApp v.{version} made with <FontAwesomeIcon icon={faHeart} /> by <a href="https://profile.rpgmax.fr" target="_blank" rel="noopener noreferrer"> RpGmAx</a></span>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
