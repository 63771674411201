import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Breadcrumb from './components/Breadcrumb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord, faYoutube, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

import moula from './assets/img/team/moula.jpg';
import cryptocademia from './assets/img/team/cryptocademia.png';
import rpgmax from './assets/img/team/rpgmax.jpg';

const TeamMember = ({ imageSrc, name, position, socialLinks }) => {
    return (
        <Col md={4}>
            <Container className="team-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".25s">
                <div className="team-img">
                    <Image src={imageSrc} alt="thumb" fluid />
                </div>
                <div className="team-content">
                    <div className="team-bio">
                        <h5>{name}</h5>
                        <span>{position}</span>
                    </div>
                    <div className="team-social">
                        {socialLinks && socialLinks.website && <a href={socialLinks.website} target='_blank'><FontAwesomeIcon icon={faHouse} className="team-si" /></a>}
                        {socialLinks && socialLinks.discord && <a href={socialLinks.discord} target='_blank'><FontAwesomeIcon icon={faDiscord} className="team-si" /></a>}
                        {socialLinks && socialLinks.twitter && <a href={socialLinks.twitter} target='_blank'><FontAwesomeIcon icon={faTwitter} className="team-si" /></a>}
                        {socialLinks && socialLinks.youtube && <a href={socialLinks.youtube} target='_blank'><FontAwesomeIcon icon={faYoutube} className="team-si" /></a>}
                        {socialLinks && socialLinks.linkedin && <a href={socialLinks.linkedin} target='_blank'><FontAwesomeIcon icon={faLinkedin} className="team-si" /></a>}
                        {socialLinks && socialLinks.facebook && <a href={socialLinks.facebook} target='_blank'><FontAwesomeIcon icon={faFacebook} className="team-si" /></a>}
                    </div>
                </div>
            </Container>
        </Col>
    );
};

export function Team() {
  return (
    <main className="main">
      <Breadcrumb pageName="L'équipe" />
      <Container className="my-4">
        <Row>
          <Col lg={6} className="mx-auto">

            <div className="site-heading text-center">
                <h2 className="site-title">Les membres d'<span>Honey Airdrop</span></h2>
                <div className="heading-divider"></div>
            </div>

          </Col>
        </Row>
        <Row>
            <TeamMember
                imageSrc={moula}
                name="Moula"
                position="Responsable projet & farming"
                socialLinks={{
                    twitter: "https://twitter.com/blackyugin",
                    youtube: "https://www.youtube.com/channel/UCIvx-ZBWx-5jAktjNwUbg6A"
                }}
            />

            <TeamMember
                imageSrc={cryptocademia}
                name="Cryptocademia"
                position="Incubateur/Advisor"
                socialLinks={{
                    website: "https://www.cryptocademia.com",
                    discord: "https://discord.gg/nVpB69KNBf",
                    twitter: "https://twitter.com/Cryptocademia_",
                    youtube: "https://www.youtube.com/@Cryptocademia",
                    linkedin: "https://www.linkedin.com/company/cryptocademia/",
                    facebook: "https://www.facebook.com/cryptocademia"
                }}
            />

           <TeamMember
                imageSrc={rpgmax}
                name="RpGmAx"
                position="Dev SC/DApp"
                socialLinks={{
                    website: "https://profile.rpgmax.fr",
                    twitter: "https://twitter.com/RpGmAx"
                }}
            />
        </Row>
      </Container>
    </main>
  );
}