import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Breadcrumb from './components/Breadcrumb';

import { Claim } from './components/Claim';
import { Airdrops } from './components/Airdrops';
import { Check } from './components/Check';

import { ConnectButton } from '@rainbow-me/rainbowkit';

export function Dashboard() {

    return (
        <main className="main">
            <Breadcrumb pageName="Dashboard" />
            <Container className="my-4">
                <Row>
                    <Col lg={6} className="mx-auto">

                        <div className="site-heading text-center">
                            <h2 className="site-title">Bienvenue au sein de <span>la ruche</span></h2>
                            <div className="connect-zone my-4"><ConnectButton /></div>
                            <div className="heading-divider"></div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 4}} className="mx-auto text-center">
                        <div className='service-item service-item-airdrops'><Airdrops /></div>
                        <div className='service-item service-item-hidden'><Check /></div>
                    </Col>
                    <Col lg={{span: 7, offset: 1}} className="mx-auto">
                        <Claim />
                    </Col>
                </Row>

            </Container>
        </main>
    );
}