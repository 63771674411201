import React, { useEffect, useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCoins } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from "react-router-dom";

import logo from "../assets/img/logo.png";

export function Header() {
  const location = useLocation();
  const [url, setUrl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => { setUrl(location.pathname); }, [location]);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <header className="header">
      <div className="main-navigation">
        <Navbar expand="lg" className="p-0" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
          <Container>
            <Navbar.Brand>
            <Link to="/" onClick={handleLinkClick}><img src={logo} alt="Logo" /></Link>
            </Navbar.Brand>
            <div className="mobile-menu-right">
              <Navbar.Toggle aria-controls="main_nav">
                <FontAwesomeIcon icon={faBars} />
              </Navbar.Toggle>
            </div>
            <Navbar.Collapse id="main_nav">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link to="/" className={`nav-link px-2${url === "/" ? " active" : ""}`} onClick={handleLinkClick}>Accueil</Link>
                </li>
                <li className="nav-item d-none">
                  <Link to="/Mint" className={`nav-link px-2${url === "/Mint" ? " active" : ""}`} onClick={handleLinkClick}>Mint</Link>
                </li>
                <li className="nav-item">
                  <Link to="/Dashboard" className={`nav-link px-2${url === "/Dashboard" ? " active" : ""}`} onClick={handleLinkClick}>Dashboard</Link>
                </li>
                <li className="nav-item">
                  <Link to="/Team" className={`nav-link px-2${url === "/Team" ? " active" : ""}`} onClick={handleLinkClick}>L'équipe</Link>
                </li>
              </ul>
              <div className="header-nav-right d-none">
                <div className="header-btn">
                  <Link to="/Mint" className="theme-btn mt-2" onClick={handleLinkClick}><FontAwesomeIcon icon={faCoins} beat className="px-2" /> Mint</Link>                  
                </div>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
}