import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeroSection from './components/HeroSection';
import FAQ from './components/FAQ';

import Honey2 from './assets/img/icon/honey-2.svg';
import BeeHive2 from './assets/img/icon/bee-hive-2.svg';
import BeeHive3 from './assets/img/icon/bee-hive-3.svg';
import Project from './assets/img/project.jpg';
import Bee2 from './assets/img/icon/bee-2.svg';
import Polygon from './assets/img/polygon.png';
import Flower from './assets/img/icon/flower.svg';

const ChooseItem = ({ icon, title, content }) => (
  <Col lg={12}>
    <div className="choose-item">
      <div className="choose-icon">
        <img src={icon} alt={title} />
      </div>
      <div className="choose-item-content">
        <h4>{title}</h4>
        <p>{content}</p>
      </div>
    </div>
  </Col>
);

const CounterBox = ({ icon, number, title }) => (
  <div className="col-lg-3 col-sm-6">
    <div className="counter-box">
      <div className="icon">
        <img src={icon} alt={`${number} ${title}`} />
      </div>
      <div>
        <span className="counter">{number}</span>
        <h6 className="title">{title}</h6>
      </div>
    </div>
  </div>
);

export function Home() {
  return (
    <main className="main">
      <HeroSection />
      <Container className="my-4">
        <Row className="align-items-center">
          <Col lg={6}>
            <div className="choose-content">
              <div className="site-heading mb-3">
                <span className="site-title-tagline">Le projet des abeilles</span><br />
                <div className="heading-divider"></div>
                <h2 className="site-title"><i>Honey Airdrop</i>, le regroupement des <span>abeilles</span> pour la récolte des <span>airdrops</span></h2>
              </div>
              <p><b>Honey Airdrop</b>, c'est 757 abeilles qui mutualisent leurs fonds pour pouvoir butiner ensemble les airdrops des projets cryptomonnaies de la manière la plus sécurisée possible. De plus, grâce à nos rapports réservés aux holders et à nos lives de formation, vous pourrez cueillir 100% des bienfaits de ce projet.</p>
              <div className="choose-wrapper mt-2">
                <Row>
                  <ChooseItem 
                    icon={Honey2} 
                    title="Économie de temps" 
                    content="Honey Airdrop réalise pour vous les actions nécessaires pour augmenter les chances de devenir éligible aux airdrops."
                  />
                  <ChooseItem 
                    icon={BeeHive2} 
                    title="Mutualisation des ressources" 
                    content="Mutualisez vos ressources avec d'autres utilisateurs pour augmenter vos chances d'obtenir des airdrops plus importants."
                  />
                  <ChooseItem 
                    icon={BeeHive3} 
                    title="Transparence et accompagnement" 
                    content="Honey Airdrop partage ses procédures d'airdrop avec ses holders et fournit des rapports réguliers sur les activités communautaires."
                  />
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="choose-right">
              <div className="choose-img">
                <img src={Project} alt="Honey Airdrop" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="counter-area bg pt-60 pb-60">
        <Container>
          <Row>
            <CounterBox icon={Bee2} number="757" title="NFTs" />
            <CounterBox icon={Flower} number="8" title="Communautés amies" />
            <CounterBox icon={BeeHive2} number="22$" title="Pour les holders Cryptocademia" />
            <CounterBox icon={Polygon} number="Mint" title="En MATIC sur Polygon" />
          </Row>
        </Container>
      </div>
      <Container>
        <FAQ />
      </Container>
    </main>
  );
}