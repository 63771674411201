import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { 
  getDefaultWallets, 
  RainbowKitProvider 
} from '@rainbow-me/rainbowkit';
import { 
  configureChains, 
  createConfig, 
  WagmiConfig 
} from 'wagmi';
import { polygon } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import './styles/honey.css';
import './styles/App.css';
import '@rainbow-me/rainbowkit/styles.css';

import Layout from "./layout/Layout";

import { Home } from './Home';
import { Check } from './Check';
import { Mint } from './Mint';
import { Dashboard } from './Dashboard';
import { Team } from './Team';

import { Scroll } from './components/Scroll';

// Configuration Setup
const { chains, publicClient } = configureChains(
  [polygon],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Honey Airdrop',
  projectId: '417c35899128b51405334f33be1176d8',
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
});

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <Router>
          <Layout>
            <Routes>
              <Route exact path="/:forcedTokenId" element={<Mint />} />
              <Route path="/" element={<Home />} />
              <Route path="/Check" element={<Check />} />
              <Route path="/Mint" element={<Mint />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/Team" element={<Team />} />
            </Routes>
          </Layout>
        </Router>
        <Scroll />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;