import React, { useEffect, useState, useCallback } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { Network, Alchemy } from 'alchemy-sdk';

import abi from '../contracts/HiveABI.json';
import { decodeEventLog } from 'viem'

import { weiConverter, timestampToDate } from './Utils.js'

const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: Network.MATIC_MAINNET,
};

const alchemy = new Alchemy(settings);
const REACT_APP_HIVE_SC_ADDRESS = process.env.REACT_APP_HIVE_SC_ADDRESS;
const REACT_APP_HIVE_SC_TOPICS = process.env.REACT_APP_HIVE_SC_TOPICS;

export function Airdrops() {

    const [isLoading, setIsLoading] = useState(true);

    const [hiveLogs, setHiveLogs] = useState([]);

    // Get all logs from the hive
    const getHiveLogs = useCallback(async () => {
        try {
            setIsLoading(true);
            const logs = await alchemy.core.getLogs({
                fromBlock: "0x0",
                address: REACT_APP_HIVE_SC_ADDRESS,
                topics: [REACT_APP_HIVE_SC_TOPICS]
            });

            const decodedData = logs.map((transaction) => {
                const topics = decodeEventLog({
                    abi: abi,
                    data: transaction.data,
                    topics: [REACT_APP_HIVE_SC_TOPICS],
                });
                return topics['args'];
            });

            decodedData.sort((a, b) => Number(b._blockTimestamp) - Number(a._blockTimestamp));

            setHiveLogs(decodedData);
            setIsLoading(false);

        } catch (error) {
            console.error('Error during getting user NFTs', error);
        }
    }, []);

    useEffect(() => {
        getHiveLogs();
    }, [getHiveLogs]);

    return (<>

        <p className="site-title-tagline text-center">Parachutages !</p>
        <p>Liste de l'ensemble des airdrops réalisés.</p>

        <Carousel data-bs-theme="dark" indicators={false}>

            {isLoading || hiveLogs.length == 0 && (
                <Carousel.Item>
                    <p><b>Chargement en cours</b><br />
                        <small>Patience...</small>
                    </p>
                </Carousel.Item>
            )}

            {hiveLogs.map((topic, index) => (

                <Carousel.Item key={index}>
                    <p><b>{weiConverter(Number(topic._amount), topic._isUSDT ? 6 : 18)} {topic._isUSDT ? 'USDT' : 'MATIC'}</b><br />
                        <small>{timestampToDate(Number(topic._blockTimestamp))}</small>
                    </p>
                </Carousel.Item>
            ))}


        </Carousel>

    </>);
}