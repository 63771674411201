import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassDollar } from '@fortawesome/free-solid-svg-icons';

import abi from '../contracts/HiveABI.json';

import { weiConverter, plural } from './Utils.js'
import { Button, Form, InputGroup, FloatingLabel, Alert } from 'react-bootstrap';
import { readContract } from '@wagmi/core'

const REACT_APP_HIVE_SC_ADDRESS = process.env.REACT_APP_HIVE_SC_ADDRESS;

export function Check() {

    const [selectedTokenID, setSelectedTokenID] = useState(null);
    const [selectedTokenInfos, setSelectedTokenInfos] = useState(null);
    const [tokenNotFound, setTokenNotFound] = useState(false);

    const handleInputChange = (event) => {
        setSelectedTokenID(event.target.value);
    };

    const fetchTokenBalances = async (event) => {
        event.preventDefault()
        if (selectedTokenID >= 1 && selectedTokenID <= 757) {
            try {
                const data = await readContract({
                    address: REACT_APP_HIVE_SC_ADDRESS,
                    abi: abi,
                    functionName: 'getBalances',
                    args: [[selectedTokenID]]
                });
                setSelectedTokenInfos({'tokenId' : selectedTokenID, balances: data});
                setTokenNotFound(false);
            } catch (error) {
                console.error('Error reading contract :', error);
                setTokenNotFound(true);
            }
        }
    };

    return (<>

        <p className="site-title-tagline">Une abeille avertie en vaut deux</p>
        <p>Vérifiez les airdrops disponibles dans un NFT en une seconde, avec des infos en direct du smart contract !</p>

        <Form onSubmit={fetchTokenBalances}>

            <InputGroup className="mb-3">
                <FloatingLabel label="ID du NFT BitBee à vérifier">
                    <Form.Control placeholder="ID du NFT BitBee à vérifier" type="number" min="1" max="757" onChange={handleInputChange} />
                </FloatingLabel>

                <Button variant="outline-secondary" type='submit'><FontAwesomeIcon icon={faMagnifyingGlassDollar} /></Button>
            </InputGroup>

        </Form>

        {selectedTokenInfos && (<>

            <Alert variant='light' className='text-center fade-in'>

                <Alert.Heading>BitBee #{Number(selectedTokenInfos['tokenId'])}</Alert.Heading>

                <b>Airdrop{plural(selectedTokenInfos[1])} actuellement à l'intérieur :</b><br />

                {selectedTokenInfos && selectedTokenInfos['balances'][0] && (<>${weiConverter(Number(selectedTokenInfos['balances'][0]), 6)} USDT</>)}
                {selectedTokenInfos && selectedTokenInfos['balances'][1] > 0 && (<> & ${weiConverter(Number(selectedTokenInfos['balances'][1]), 18)} MATIC</>)}

            </Alert>

        </>)}

        {tokenNotFound && (<><Alert variant='danger' className='text-center'>BitBee introuvable !</Alert></>)}

    </>);
}