import { Footer } from "../components/Footer";
import { Header } from '../components/Header';

const Layout = ({ props, children }) => {

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer props={props} />
    </>
  );
};

export default Layout;