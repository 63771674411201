import React from 'react';
import { Container, Row, Col, Image, Accordion, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

import faq from '../assets/img/faq.jpg';

function FAQ() {
  return (
    <div className="faq-area my-4">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <div className="faq-left">
              <div className="faq-img">
                <Image src={faq} alt="" />
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="faq-right">
              <div className="site-heading mb-3">
                <span className="site-title-tagline">Foire aux Questions</span><br />
                <div className="heading-divider"></div>
                <h2 className="site-title my-3">Questions <span>fréquemment</span> posées</h2>
              </div>
              <div className="mt-3">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <FontAwesomeIcon icon={faQuestion} /> Pourquoi le projet Honey Airdrop ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Beaucoup de personnes se plaignent de ne pas avoir le temps, les compétences ou les ressources pour faire du farming d’airdrops. C’est pourquoi nous avons décidé de mettre en place des NFTs qui permettront à tous de participer au farm de manière passive. Tout le monde aura accès au contenu éducatif et aux rapports de farm permettant aux actifs de pouvoir farmer en plus de leur côté de la meilleure des manières possibles.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <FontAwesomeIcon icon={faQuestion} /> Combien de NFTs et à quel prix ?
                    </Accordion.Header>
                    <Accordion.Body>
                      757 NFTs (post burn) au prix de 22$, 27$ ou 40$ selon la phase de mint que vous utilisez.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <FontAwesomeIcon icon={faQuestion} /> Quelle est la blockchain utilisée par le projet et pourquoi ?
                    </Accordion.Header>
                    <Accordion.Body>
                      La blockchain utilisée est Polygon, car le coût d’utilisation de celle-ci est très bas. Idéal autant pour le projet que pour la fonction claim des bénéficiaires de nos NFTs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <FontAwesomeIcon icon={faQuestion} /> À quelle phase de mint suis-je éligible ?
                    </Accordion.Header>
                    <Accordion.Body>
                      Si vous êtes holders de Cryptocademia (<a href="https://opensea.io/collection/b3ar-market" target="_blank">B3ars</a> ou <a href="https://opensea.io/collection/b4d-bull" target="_blank">B4D Bull</a>), le prix est de 22$. Vous pouvez mint 1 NFT par NFT détenu.<br />Si vous êtes holders de nos communautés amies, le prix est de 27$. Vous pouvez mint 1 NFT par NFT de collection ami détenu.<br />Si vous n’êtes pas holder, le prix est de 40$. Vous pouvez mint autant de NFT que vous le désirez.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FAQ;
