import React, { useEffect } from 'react';

export function Scroll() {
    useEffect(() => {
        const handleScroll = () => {

            const navbar = document.querySelector('.navbar');
            if (window.scrollY > 50) {
                navbar.classList.add("fixed-top");
            } else {
                navbar.classList.remove("fixed-top");
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <>
        </>
    )
}