export const plural = (count) => count >= 2 ? 's' : '';

export function weiConverter(_amount, _decimals) {
    const factor = Math.pow(10, _decimals);
    const intAmount = Math.floor(_amount / factor);
    let decimalAmount = Math.floor((_amount % factor) * 100 / factor); // 100 par défaut

    let decimalsStr = decimalAmount.toString();
    if (decimalAmount < 10) {
        decimalsStr = '0' + decimalsStr;
    }

    return `${intAmount}.${decimalsStr}`;
}

export function timestampToDate(timestamp) {
    const date = new Date(timestamp * 1000);

    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().substring(2)}`;

    return (<>{formattedDate}</>);
};